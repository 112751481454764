import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/Footer/index"
import DistribuidoresParceiros from "../components/DistribuidoresParceiros/index"
import PrincipaisParceiros from "../components/PrincipaisParceiros/index"

import ArdrakPorCima from "../components/SejaParceiro/ardrakporcima"
import MapaBrasil from "../components/SejaParceiro/mapabrasil"
import Produtos from "../components/SejaParceiro/produtos"

import colors from "../styles/colors"
import { Container } from "../styles/global"

import ParceiroHeroFigure from "../images/SejaParceiro/hero-seja-parceiro.svg"

const SejaParceiro = () => (
  <>
    <SEO title="Seja um parceiro!" />
    <Header />
    <StyledContainer>
      <HeroSection>
        <div className="text">
          <h1>Seja um parceiro Ardrak</h1>
          <p>A Ardrak domina o mercado há 30 anos. Faça parte do nosso time!</p>
          <Botao to="/contato">Entre em contato</Botao>
        </div>
        <div className="image">
          <img src={ParceiroHeroFigure} alt="Parceiro" />
        </div>
      </HeroSection>
      <SectionTwo>
        <div className="text">
          <Titulo>Líder consolidada</Titulo>
          <Paragrafo>
            Seja destaque revendendo os produtos mais procurados no ramo de
            gengibre.
          </Paragrafo>
          <Botao to="nossa-empresa">CONHEÇA A EMPRESA</Botao>
        </div>
        <div className="image">
          <ArdrakPorCima />
        </div>
      </SectionTwo>
      <SectionThree>
        <div className="image">
          <MapaBrasil />
        </div>
        <div className="texto">
          <Titulo>Centrais estrategicamente posicionadas</Titulo>
          <Paragrafo>
            Ampla rede de distribuição em todo o Brasil pronta para te atender.
          </Paragrafo>
        </div>
      </SectionThree>
      <SectionFour>
        <DistribuidoresParceiros />
      </SectionFour>
      <SectionFive>
        <Titulo>Seus clientes vão adorar os produtos Ardrak!</Titulo>
        <div className="image">
          <Produtos />
        </div>
      </SectionFive>
      <SectionSix>
        <Titulo>A aprovação passa por você!</Titulo>
        <Paragrafo>
          Experimente nossos produtos. <br />
          Garantimos: você vai se apaixonar e querer em sua loja!
        </Paragrafo>
        <Botao to="/produtos">CONHEÇA OS PRODUTOS</Botao>
      </SectionSix>
      <SectionSeven>
        <PrincipaisParceiros />
      </SectionSeven>
    </StyledContainer>
    <Footer />
  </>
)

const StyledContainer = styled(Container)`
  @media (max-width: 1200px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`

const Botao = styled(Link)`
  display: block;
  width: fit-content;
  padding: 13px 30px 8px 30px;
  border-radius: 50px;
  background-color: ${colors.VERDE_CLARO};

  color: ${colors.VERDE};
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    background-color: ${colors.VERDE_ESCURO};
    color: white;
  }
`

const Titulo = styled.h1`
  color: ${colors.VERDE_DOIS};
  font-size: 65px;
  line-height: 70px;
  font-weight: 500;

  @media (max-width: 670px) {
    font-size: 45px;
    line-height: 50px;
  }
`

const Paragrafo = styled.p`
  color: ${colors.CINZA_PADRAO};
  font-size: 24px;
  line-height: 32px;
  max-width: 523px;
`

const HeroSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .text {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    h1 {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 90px;
      font-weight: 500;
      color: ${colors.VERDE_DOIS};
      text-align: center;
    }
    p {
      padding-bottom: 25px;
      max-width: 690px;
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      color: ${colors.CINZA_PADRAO};
    }
  }

  img {
    padding-bottom: 30px;
  }
  @media (max-width: 1000px) {
    img {
      width: 728px;
    }
  }

  @media (max-width: 670px) {
    display: flex;
    flex-flow: column-reverse nowrap;

    .text {
      h1 {
        color: ${colors.VERDE_ESCURO};
        font-size: 53px;
      }
      p {
      }
    }
    img {
      width: 628px;
      margin-left: 60px;
    }
  }
`

const SectionTwo = styled.section`
  padding-top: 50px;
  padding-bottom: 150px;
  display: flex;
  align-items: center;

  .text {
    width: 50%;

    ${Titulo} {
      padding-bottom: 20px;
    }
    ${Paragrafo} {
      padding-bottom: 20px;
    }
  }
  .image {
    width: 50%;
  }

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    width: 100%;

    .text {
      margin-top: 20px;
      width: 100%;
    }
    .image {
      width: 100%;
    }
  }
`

const SectionThree = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div.image {
    width: 520px;
  }
  div.texto {
    width: 50%;

    ${Titulo} {
      padding-bottom: 20px;
    }
  }
  @media (max-width: 1050px) {
    flex-direction: column;

    div.image {
      max-width: 520px;
      width: 100%;
    }
    div.texto {
      margin-top: 20px;
      width: 100%;
    }
  }
`

const SectionFour = styled.section`
  p {
    font-size: 18px;
    color: ${colors.CINZA_PADRAO};
    max-width: 680px;
    text-align: center;
  }
`

const SectionFive = styled.section`
  padding-bottom: 200px;
  display: flex;
  align-items: center;

  .image {
    width: 50%;
  }
  ${Titulo} {
    width: 50%;
  }

  @media (max-width: 850px) {
    padding-bottom: 130px;
    display: flex;
    flex-direction: column;

    ${Titulo} {
      width: auto;
      text-align: center;
    }
    .image {
      width: 600px;
    }
  }
`

const SectionSix = styled.section`
  padding-bottom: 50px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  ${Titulo} {
    text-align: center;
  }
  ${Paragrafo} {
    padding: 20px 0;
    text-align: center;
  }
`

const SectionSeven = styled.section``

export default SejaParceiro
